var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BTable",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: { dark: "", "main-table": "", hover: "", striped: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "table-busy",
                fn: function() {
                  return [_c("Loading")]
                },
                proxy: true
              },
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        "BTable",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }