var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppTable", {
    ref: "table",
    attrs: { fields: _vm.fields, items: _vm.items, "primary-key": "id" },
    scopedSlots: _vm._u([
      {
        key: "cell(bookmark)",
        fn: function(data) {
          return [
            _c("BookmarkToggle", {
              attrs: { value: _vm.hasBookmark(data.item.id) },
              on: {
                update: function($event) {
                  return _vm.setBookmark(data.item.id, $event)
                }
              }
            })
          ]
        }
      },
      {
        key: "cell(team)",
        fn: function(data) {
          return [
            _c(
              "TeamCell",
              _vm._b({ attrs: { link: "" } }, "TeamCell", data.item, false)
            )
          ]
        }
      },
      {
        key: "cell(matches)",
        fn: function(data) {
          return [
            _c("LoadTeamOverviewCell", {
              attrs: { id: data.item.id },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var overview = ref.overview
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("sumMatches")(overview)) + " "
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      },
      {
        key: "cell(wins)",
        fn: function(data) {
          return [
            _c("LoadTeamOverviewCell", {
              attrs: { id: data.item.id },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var overview = ref.overview
                      return [
                        _vm._v(" " + _vm._s(_vm._f("sumWins")(overview)) + " ")
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      },
      {
        key: "cell(loses)",
        fn: function(data) {
          return [
            _c("LoadTeamOverviewCell", {
              attrs: { id: data.item.id },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var overview = ref.overview
                      return [
                        _vm._v(" " + _vm._s(_vm._f("sumLoses")(overview)) + " ")
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      },
      {
        key: "cell(roundsWonPerMatch)",
        fn: function() {
          return [_vm._v(" TBA ")]
        },
        proxy: true
      },
      {
        key: "cell(roundsLostPerMatch)",
        fn: function() {
          return [_vm._v(" TBA ")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }