var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-auto" },
    [
      _c("b-pagination-nav", {
        attrs: {
          limit: 1000,
          "link-gen": _vm.linkGen,
          "page-gen": _vm.pageGen,
          "number-of-pages": _vm.numberOfPages,
          "hide-goto-end-buttons": "",
          "hide-ellipsis": ""
        },
        model: {
          value: _vm.currentPageNumber,
          callback: function($$v) {
            _vm.currentPageNumber = $$v
          },
          expression: "currentPageNumber"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }