import { render, staticRenderFns } from "./BookmarkedTeams.vue?vue&type=template&id=55874a29&"
import script from "./BookmarkedTeams.vue?vue&type=script&lang=js&"
export * from "./BookmarkedTeams.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3544011153/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55874a29')) {
      api.createRecord('55874a29', component.options)
    } else {
      api.reload('55874a29', component.options)
    }
    module.hot.accept("./BookmarkedTeams.vue?vue&type=template&id=55874a29&", function () {
      api.rerender('55874a29', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/controllers/BookmarkedTeams.vue"
export default component.exports