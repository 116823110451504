var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Teams")]),
      _c(
        "AppTabs",
        [
          _c("AppTab", { attrs: { lazy: "", title: "Search" } }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onSelect(null)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap" },
                  [
                    _c("AppAutosuggest", {
                      ref: "autosuggest",
                      attrs: {
                        "input-props": _vm.inputProps,
                        "should-render-suggestions":
                          _vm.shouldRenderSuggestions,
                        "section-configs": _vm.sectionConfigs,
                        suggestions: _vm.sectionedSuggestions,
                        value: _vm.focused
                          ? _vm.search
                          : _vm.search || _vm.inputProps.placeholder
                      },
                      on: {
                        input: _vm.onInput,
                        selected: _vm.onSelect,
                        focus: _vm.onFocus,
                        blur: _vm.onBlur
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "before-suggestions",
                          fn: function() {
                            return [
                              _vm.loading
                                ? _c("Loading")
                                : _vm.error
                                ? _c("ErrorAlert", [
                                    _vm._v(" " + _vm._s(_vm.error) + " ")
                                  ])
                                : _vm.search.length < 2
                                ? _c("InfoAlert", [
                                    _vm._v(
                                      " Enter at least 2 characters to get results "
                                    )
                                  ])
                                : !_vm.suggestions.length
                                ? _c("InfoAlert", [
                                    _vm._v(" No matching teams ")
                                  ])
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "default",
                          fn: function(ref) {
                            var suggestion = ref.suggestion
                            return [
                              _c(
                                "TeamCell",
                                _vm._b(
                                  { attrs: { link: "", variant: "row" } },
                                  "TeamCell",
                                  suggestion.item,
                                  false
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "warning",
                              disabled: _vm.showResults && _vm.loading
                            }
                          },
                          [
                            _vm.showResults && _vm.loading
                              ? _c("span", [_vm._v("Loading...")])
                              : _c("span", [_vm._v("Search")])
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "reset",
                              variant: "outline-warning"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onInput("")
                              }
                            }
                          },
                          [_vm._v(" Reset ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm.showResults
              ? _c(
                  "div",
                  [
                    _c("TeamSearchController", {
                      attrs: {
                        filter: _vm.searchFilter,
                        table: _vm.bookmarkController
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "AppTab",
            { attrs: { lazy: "", title: "Bookmarked" } },
            [_c("BookmarkedTeamsController")],
            1
          ),
          _c(
            "AppTab",
            { attrs: { lazy: "", title: "All (A-Z)" } },
            [
              _c("AlphabetNav", {
                model: {
                  value: _vm.alphaNav,
                  callback: function($$v) {
                    _vm.alphaNav = $$v
                  },
                  expression: "alphaNav"
                }
              }),
              _vm.alphaNav
                ? _c("CatalogTeamsController", {
                    attrs: { letter: _vm.alphaNav }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }