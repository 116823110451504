var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiListController", {
    attrs: { fetch: _vm.fetch, filter: _vm.fetchParams, name: "Teams" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var items = ref.items
          return [
            _c(_vm.tableComponent, {
              tag: "component",
              attrs: { items: items }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }