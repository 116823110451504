var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiListController", {
    attrs: {
      filter: { letter: _vm.letter },
      fetch: _vm.fetch,
      name: "Teams",
      "no-pagination": ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var items = ref.items
          return [_c("TeamBookmarkController", { attrs: { items: items } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }